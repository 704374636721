import { graphql } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import { unwrapFirst, unwrapConnection } from "../util";
import {
  Layout,
  HelmetSeo,
  HorizontalContainer,
  Section,
  MediaResourceTile,
} from "../components/legacy";
import { Color } from "../constants/legacy";

type Props = {
  data: GatsbyTypes.MediaPageQuery;
};

export const query = graphql`
  query MediaPage {
    prismicMediaPage {
      data {
        pre_heading
        heading

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }

    allPrismicMediaResource {
      edges {
        node {
          data {
            posted_at
            ...MediaResourceTile
          }
        }
      }
    }
  }
`;

export default function MediaPage({ data }: Props) {
  const page = data.prismicMediaPage?.data;
  if (!page) throw new Error("page missing");

  const mediaResourceNodes = unwrapConnection(
    data.allPrismicMediaResource
  ).sort(
    (a, b) =>
      (a.data?.posted_at ?? "").localeCompare(b.data?.posted_at ?? "") * -1
  );

  return (
    <Layout>
      <HelmetSeo {...page} />

      <Section>
        <HorizontalContainer>
          <StyledPreHeading>{page.pre_heading}</StyledPreHeading>
          <StyledHeading>{page.heading}</StyledHeading>

          <StyledTiles>
            {mediaResourceNodes.map(
              ({ data }) =>
                data && (
                  <MediaResourceTile
                    key={`${data.title}${data.posted_at}`}
                    media={data}
                  />
                )
            )}
          </StyledTiles>
        </HorizontalContainer>
      </Section>
    </Layout>
  );
}

const StyledPreHeading = styled.h2`
  color: #808080;
  font-weight: 500;
  font-size: 18px;
  margin: 0 0 5px;
`;

const StyledHeading = styled.h1`
  color: ${Color.darkBlue};
  font-size: 40px;
  font-weight: 700;
  margin: 0 0 50px;
`;

const StyledTiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 80px -10px;
`;
